import React from 'react'
import styled from 'styled-components'
import '../styles/spectre.css';
import '../styles/icons/css/icons.css';

// import OriginSwitch from './Typeahead/OriginSwitch.js'
import TypeAheadOriginTruckLoad from '../FunctionalComponents/Typeahead/Destination/TypeAheadDestinationTruckLoad.js'
import TypeAheadDestinationTruckLoad from '../FunctionalComponents/Typeahead/Origin/TypeAheadOriginTruckLoad.js'
import InputPickupDate from '../InputComponents/InputPickupDate.js'

const FunctionalComponentTruckLoad = styled.div`

  // 🎛
  width: 100%;
  .TruckLoadGrid {
  margin: 0 auto;
  max-width: 1360px;
  // 🎨
  padding-top: 1em;
  padding-bottom: 3em;
  background-color: #f7f7f7;
  border-top: 2px solid #0033a1;
  border-left: 1px solid #e7edf0;
  border-bottom: 1px solid #e7edf0;
  border-right: 1px solid #e7edf0;
  position: relative;
  z-index: 1;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  font-family: 'Open Sans';
  color: #000;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.2px;
  // 📰
  display: grid;
  grid-column-gap: 50px;
  grid-template-rows: 1.5fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    "c_bin_1"
    "c_bin_2"
    "c_set_as_default";

  .es-c-bin-1 {
    grid-area: c_bin_1; // 🗺
  }
}
label {
  font-family: 'Open Sans';
}
`;



const EchoShipFunctionalComponent = () => {
  return (
    <FunctionalComponentTruckLoad>
      <div className="TruckLoadGrid">
        <div>
          <div className="form-group es-i-pickup">
            <label
              className="form-label label-lg"
              htmlFor="input-pickup-zipcode"
            >
              Origin <span>*</span>
            </label>
          </div>
          <TypeAheadOriginTruckLoad placeholder="Search for City, St or a Zip Code" />
        </div>

        <div>
          <div className="form-group es-i-pickup">
            <label
              className="form-label label-lg"
              htmlFor="input-pickup-zipcode"
            >
              Destination <span>*</span>
            </label>
          </div>
          <TypeAheadDestinationTruckLoad placeholder="Search for City, St or a Zip Code" />
        </div>

        <InputPickupDate />
      </div>
    </FunctionalComponentTruckLoad>
  )
}

export default EchoShipFunctionalComponent;