import _ from "lodash";

import React, { Component } from "react";
import { Search } from "semantic-ui-react";
import "../../../styles/type-ahead.css";

// const source = _.times(15, () => ({
//   title: faker.company.companyName(),
//   description: faker.fake(
//     "{{address.streetAddress}}, {{address.city}}, {{address.stateAbbr}}, {{address.zipCode}}, {{address.country}}"
//   )
// }));

const source = _.times(1, () => ({
  "title": "Echo Global Logistics",
  "description": "600 W Chicago Ave #725, Chicago, IL 60610"
}));



export default class TypeAheadOriginTruckLoad extends Component {
  componentWillMount() {
    this.resetComponent();
  }

  resetComponent = () =>
    this.setState({ isLoading: false, results: [], value: "" });

  handleResultSelect = (e, { result }) =>
    this.setState({ value: result.title });

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value });

    setTimeout(() => {
      if (this.state.value.length < 1) return this.resetComponent();

      const re = new RegExp(_.escapeRegExp(this.state.value), "i");
      const isMatch = result => re.test(result.title);

      this.setState({
        isLoading: false,
        results: _.filter(source, isMatch)
      });
    }, 300);
  };

  render() {
    const { isLoading, value, results } = this.state;

    return (

          <Search
            fluid
            loading={isLoading}
            onResultSelect={this.handleResultSelect}
            onSearchChange={_.debounce(this.handleSearchChange, 500, {
              leading: true
            })}
            results={results}
            value={value}
            {...this.props}
          />

    );
  }
}