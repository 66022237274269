import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import ButtonGreen from '../../../components/Buttons/ButtonGreen.js'

const NavigationTruckLoad = styled.div`
  // 🎛
  // 🎨
  // 🖋
  font-family: 'Open Sans';
  width: 100%;
`;

const PGComponentWrapper = styled.div`
  // 📰
  padding-bottom: 10px;
  padding-top: 10px;
  margin: 0 auto;
  max-width: 1360px;
  margin-bottom: 30px;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 2fr 2fr 230px;
  grid-template-areas:
  "sg_btn_previous sg_btn_wrapper sg_btn_wrapper sg_btn_next";
  .ButtonWrapper {
    .ButtonPrevious {
      grid-template-areas: sg_btn_previous;
    }
    .ButtonGreen {
      grid-template-areas: sg_btn_next;
      justify-self: end;
    }
    .ButtonGreen {
      grid-template-areas: sg_btn_wrapper;
    }
  }
`;

const ButtonWrapper = styled.div`

`;


const EchoShipNavStepper = () => {
  return (
    <NavigationTruckLoad>
        <PGComponentWrapper>
        <ButtonWrapper></ButtonWrapper>
        <ButtonWrapper></ButtonWrapper>
        <ButtonWrapper></ButtonWrapper>
        
        <Link to="/quote/CarrierResults/">
          <ButtonGreen 
            buttonText="Request Quote"
          />
        </Link>
        </PGComponentWrapper>

    </NavigationTruckLoad>
  )
}

export default EchoShipNavStepper;