import React from 'react'
import styled from 'styled-components'

import DayPickerInput from "react-day-picker/DayPickerInput";

import '../styles/spectre.css';
import '../styles/icons/css/icons.css';
import '../styles/calendar-input.css';

const InputPickupDate = styled.div`
.form-group.es-i-address-1 {

  font-family: "Open Sans";
  label.form-label {
    margin-bottom: 5px;
    font-weight: 700;
    color: #666;
    font-size: 14px;
    font-weight: 600;
    span {
      color: #26c1fb;
    }
  }
}

`;

const EchoShipInput = () => {
  return (
    <InputPickupDate>
        <label className="form-label label-lg" htmlFor="input-requested-delivery-date">
          Pickup Date <span>*</span>
        </label>
        <DayPickerInput placeholder="DD/MM/YYYY" format="DD/MM/YYYY" />
    </InputPickupDate>
  )
}

export default EchoShipInput;