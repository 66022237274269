import React from 'react'
import styled from 'styled-components'

// Import Page Components
import FunctionalComponentTruckLoad from '../../../FunctionalComponents/FunctionalComponentTruckLoad.js'

import NavigationTruckLoad from '../../../PageComponents/Navigation/Quote/NavigationTruckLoad.js'
import TruckLoadTabs from '../../../components/layouts/TruckLoadTabs.js'


const GContentTruckload = styled.div`
  background: transparent;
  // 🖋
  font-family: 'Open Sans';
  // 📰
  display: grid;
  /* grid-template-rows: 250px 250px 250px 150px; */
  grid-template-rows: 39px 150px 0.5fr 6fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "sg_tabs"
    "sg_origin"
    "sg_navigation_stepper";

  .SGGetQuoteTabs {
    grid-area: sg_tabs; // 🗺
    background: none !important;
  }
  .SGOrigin {
    grid-area: sg_origin; // 🗺
    background: none !important;
    z-index: 1;
  }

  .SGNavigationStepper {
    grid-area: sg_navigation_stepper; // 🗺
    background: none !important;
  }

  @media only screen and (max-width: 768px) {
    display: grid;
    grid-template-rows: 450px 450px 450px 150px;
    grid-template-columns: 1fr;
    grid-template-areas:
    "sg_origin"
    "sg_destination"
    "sg_add_items"
    "sg_navigation_stepper";
    
  }
  .ui.modal {
    font-size: 1rem;
    margin: 0 auto;
}
`;
const SGGetQuoteTabs = styled.div`
`;
const SGOrigin = styled.div`
    border-radius: 2px;
`;
const SGNavigationStepper = styled.div`
    border-radius: 2px;
`;


const EchoShipGridComponentContent = () => {
  return (

    <GContentTruckload>

      <SGGetQuoteTabs>
        <TruckLoadTabs></TruckLoadTabs>
      </SGGetQuoteTabs>

      <SGOrigin>
        <FunctionalComponentTruckLoad></FunctionalComponentTruckLoad>
      </SGOrigin>


      <SGNavigationStepper>


        <NavigationTruckLoad></NavigationTruckLoad>
      </SGNavigationStepper>


    </GContentTruckload>

  )
}
export default EchoShipGridComponentContent;