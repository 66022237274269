
import React from 'react'
import styled from 'styled-components'

// Import Page Level Components
import NavPrimarySingle from '../../components/layouts/NavPrimarySingle.js'
import SecondaryNav from '../../components/layouts/NavSecondary.js'
import Footer from '../../components/layouts/Footer.js'


import GContentTruckload from '../../components/ContentGrids/Quote/TruckloadContentGrid.js'

const PGComponentTruckload = styled.div`

  background-color: white;
  // 🖋
  font-family: 'Open Sans';
  // 📰
  display: grid;
  grid-template-rows: minmax(0.1fr, 5fr)0.1fr 0.3fr 0.2fr 5fr 0.2fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "nav_secondary"
    "nav_primary"
    "page_title"
    "g_content"
    "g_footer";

  .NavPrimary {
    grid-area: nav_primary; // 🗺
    align-self: start;
  }
  .SecondaryNav {
    grid-area: nav_secondary; // 🗺
    align-self: start;
  }
  .PageTitle {
    grid-area: page_title; // 🗺
    align-self: start;
  }
  .GContentGetQuote {
    grid-area: g_content; // 🗺
  }
  .Footer {
    grid-area: g_footer; // 🗺
    align-self: end;
  }
`;
const GComponentPageTitle = styled.div`
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;  
`;
const PageTitle = styled.div`


    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
    margin: 0 auto;
    max-width: 1360px;

    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    text-transform: none;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
`;

const PageGridComponent = () => {
  return (

    <PGComponentTruckload>

      <SecondaryNav></SecondaryNav>
      <NavPrimarySingle></NavPrimarySingle>
        <GComponentPageTitle>
          <PageTitle>TruckLoad</PageTitle>
        </GComponentPageTitle>
      <GContentTruckload></GContentTruckload>
      <Footer></Footer>

    </PGComponentTruckload>

  )
}

export default PageGridComponent;